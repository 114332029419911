.App {
	text-align: center;
	height: 100%;
}

#root {
	height: 100%;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.FormGroup {
	margin: 0 15px 20px;
	padding: 0;
	border-style: none;
	background-color: #7795f8;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

form#payment-form {
	margin-top: 19px;
}

.CheckoutStripe-content-wrapper button, .Stripe_payment_button {
	display: block;
	font-size: 16px;
	width: 100%;
	height: 40px;
	margin: auto;
	margin-top: 30px !important;
	background-color: var(--theme-primary);
	/* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6; */
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}

.CheckoutStripe-content-wrapper button:active {
	background-color: #969ce2;
	transform: scale(0.99);
}


.variant__type__heading {
	font-size: 12px !important;
	/* padding: 8px; */
}

.category__name {
	display: flex;
	width: max-content;
}

.product__category__list {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
	overflow-x: auto;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 10;
	margin-bottom: 10px;
}

.product__category__name {
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: bold;
	margin: 10px 0px 10px 0px;
	border-radius: 8px;
	min-width: max-content;
	cursor: pointer;
}

.product__category__filter {
	display: flex;
	gap: 5px;
	width: 100%;
	align-items: center;
	margin: 0px 0px 20px 0px;
}

.product__category__filter__wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 90%;
	justify-content: start;
	align-items: center;
	gap: 4px;
}

.product__category__filter__clear__icon {
	width: 9%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
}

.product__category__filter__clear__text {
	/* color: black; */
	/* font-size: 10px; */
}

.product__category__filter__label {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
	padding: 3px 10px !important;
	border-radius: 20px;
	background-color: #e8e7e7;
	color: black;
	cursor: pointer;
}

.product__category__filter__text {
	font-size: 9px;
}

.product__category__filter__cross__icon {
	/* width: 15px;
	height: 15px; */
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 9px;
}

.product__category__name__selected {
	border: 1px solid var(--theme-primary);
	background-color: rgba(var(--theme-primary-hex), 0.1);
	color: var(--theme-primary);
	font-weight: 500 !important;
}

.product__category__name__not__selected {
	border-width: 1px;
	border-style: solid;
	border-color: rgba(112, 112, 112, 1);
	/* border: 1px solid rgba(42, 41, 92, 0.6); */
	background-color: transparent;
	font-weight: 500 !important;
	/* color: rgba(var(--theme-primary-hex), 0.5); */
	/* color: rgba(42, 41, 92, 0.6); */
}

.product__category__list::-webkit-scrollbar {
	display: none;
}