html, body {
    /* height: 100%; */
    font-family: 'Poppins', sans-serif !important;
    /* overflow: hidden !important; */
}

.react-tel-input .form-control {
    font-size: 14px !important;
    font-family: 'Poppins', sans-serif !important;
}

p, h1, h2, h3, h4, h5, h6, button {
    margin: 0 !important;
    font-family: 'Poppins', sans-serif !important;
}

:root {
    --blue: #40B4D0;
    --red: #FF3327;
    --violet: #2A295C;
    --white: #ffffff;
    --grey: #585858;
    --Bgred: #E50000;
}

/* @font-face {
    font-family: 'VerizonNHGDSBold';
    src: url('/public/assets/fonts/VerizonNHGDSBold.eot');
    src: url('/public/assets/fonts/VerizonNHGDSBold.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGDSBold.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGDSBold.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGDSBold.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGDSBold.svg#VerizonNHGDSBold') format('svg');
}
@font-face {
    font-family: 'VerizonNHGDSBoldItalic';
    src: url('/public/assets/fonts/VerizonNHGDSBoldItalic.eot');
    src: url('/public/assets/fonts/VerizonNHGDSBoldItalic.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGDSBoldItalic.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGDSBoldItalic.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGDSBoldItalic.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGDSBoldItalic.svg#VerizonNHGDSBoldItalic') format('svg');
}
@font-face {
    font-family: 'VerizonNHGDSItalic';
    src: url('/public/assets/fonts/VerizonNHGDSItalic.eot');
    src: url('/public/assets/fonts/VerizonNHGDSItalic.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGDSItalic.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGDSItalic.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGDSItalic.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGDSItalic.svg#VerizonNHGDSItalic') format('svg');
}
@font-face {
    font-family: 'VerizonNHGDSRegular';
    src: url('/public/assets/fonts/VerizonNHGDSRegular.eot');
    src: url('/public/assets/fonts/VerizonNHGDSRegular.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGDSRegular.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGDSRegular.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGDSRegular.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGDSRegular.svg#VerizonNHGDSRegular') format('svg');
}
@font-face {
    font-family: 'VerizonNHGTXBold';
    src: url('/public/assets/fonts/VerizonNHGTXBold.eot');
    src: url('/public/assets/fonts/VerizonNHGTXBold.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGTXBold.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGTXBold.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGTXBold.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGTXBold.svg#VerizonNHGTXBold') format('svg');
}
@font-face {
    font-family: 'VerizonNHGTXBoldItalic';
    src: url('/public/assets/fonts/VerizonNHGTXBoldItalic.eot');
    src: url('/public/assets/fonts/VerizonNHGTXBoldItalic.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGTXBoldItalic.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGTXBoldItalic.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGTXBoldItalic.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGTXBoldItalic.svg#VerizonNHGTXBoldItalic') format('svg');
}
@font-face {
    font-family: 'VerizonNHGTXItalic';
    src: url('/public/assets/fonts/VerizonNHGTXItalic.eot');
    src: url('/public/assets/fonts/VerizonNHGTXItalic.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGTXItalic.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGTXItalic.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGTXItalic.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGTXItalic.svg#VerizonNHGTXItalic') format('svg');
}
@font-face {
    font-family: 'VerizonNHGTXRegular';
    src: url('/public/assets/fonts/VerizonNHGTXRegular.eot');
    src: url('/public/assets/fonts/VerizonNHGTXRegular.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGTXRegular.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGTXRegular.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGTXRegular.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGTXRegular.svg#VerizonNHGTXRegular') format('svg');
} */

.MainWhole-wrapper-container-2 {
    width: 560px;
    background-color: #ffffff;
    margin: auto;
    height: 100dvh !important;
    /* border: 2px solid var(--theme-primary) solid rgb(196 193 193); */
    border-top: 0px;
    /* height: 99vh; */
}

.MainWhole-wrapper-container {
    width: 560px;
    /* background-color: rgb(242,242,242); */
    margin: auto;
    /* height: 100vh !important; */
    /* border: 2px solid var(--theme-primary) solid rgb(196 193 193); */
    border-top: 0px;
    /* height: 99vh; */
    height: 100%;
}

.DesktopMain-wrapper {
    background-color: rgb(242, 242, 242);
    /* display: flex;
    align-items: center; */
}

.Login_page-wholeMain-wrapper {
    display: flex;
    align-items: center;
}

.DesktopMain-wrapper .Header-login-logout-btn {
    cursor: pointer;
    color: black;
    text-decoration: none;
    font-weight: 600;
}

.admin-header-acitve {
    color: rgb(47, 94, 164) !important;
}

.MainHeader-wrapper {
    background: transparent;
    position: fixed;
    top: 0;
    /* padding-bottom:8px !important; */
    z-index: 10 !important;
    left: 0;
    width: 100%;
}

.headerscroll {
    box-shadow: 0px 0px 10px #00000029;
}

.MainHeader-wrapper_copy {
    background: white;
    position: fixed;
    top: 0;
    z-index: 0 !important;
    left: 0;
    width: 100%;
}

.AdminHeader-right-side-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AdminHeader-right-side-wrapper div {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center;
}

.MainHeader-logo-div {
    /* width:50%; */
    text-align: start;
    display: flex;
    align-items: end;
    /* padding-bottom: 5px; */
    /* gap: 12px; */
    /* justify-content: center; */
}

/* .MainHeader-logo-div img {
    height: 35px !important;
} */

.MainHeader_second_div {
    /* padding-right:35px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.MainHeader_second_div svg {
    width: 20px;
    /* margin-right: 3px; */
}

.MainHeader-logo-div-wrapper {
    padding: 12px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    width: 560px;
    margin: 0 auto;
    /* margin: 0 20px 0 20px; */
    background-color: white;
    border-radius: 0px 0px 7px 7px;
    box-shadow: 0px 3px 6px #00000029;
}

.MainHeader-logo-div p {
    /* position: absolute;
    left: 23px;
    top: 49px; */
}

.MainHeader-logo-div img {
    width: 100%;
}

.Congratulation_header_2 {
    display: flex;
    align-items: center;
    justify-content: end;
}

.Congratulation_header_2 h5 {
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
}

.Congratulation_header_2 p {
    font-size: 14px;
    white-space: nowrap;
    text-align: end;
}

.Congratulation_header_2 svg {
    font-size: 36px;
    font-weight: 100;
    color: var(--theme-primary);
    /* width:90px; */
}

.Header_div_congratulations {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    padding-left: 0px;
    width: 560px;
    margin: auto;
    padding: 5px 0px;
}

.Congratulation_header_image {
    width: 150px !important;
}

.Main-Wrapper-Container, .CartPage-Main-wrapper {
    padding: 30px;
    position: relative;
    /* padding-bottom:10px; */
    padding-top: 100px;
}

.ItemListMain-Wrapper {
    padding-top: 55px;
}

.ItemListMain-Wrapper, .CartPage-Main-wrapper {
    padding-bottom: 30px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: var(--theme-primary);
}

#Store_dropdown_select button {
    background-color: transparent;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D3D3D3;
    border-radius: 7px;
    font-size: 13px;
    padding: 3px 10px !important;
    /* margin: 10px !important; */
}

#Store_dropdown_select button:focus {
    border: 1px solid #D3D3D3;
    box-shadow: none;
}

.QR-Code-wrapper-whole {
    padding-bottom: 30px;
    text-align: center;
}

.QR-Code-wrapper-whole p {
    font-weight: bold;
    font-size: 17.9px;
}

.QR-Code-wrapper-whole-inner-one h3 {
    color: var(--theme-primary);
    font-size: 22px;
}

.Free-delivery-wrapper {
    text-align: left;
    background: linear-gradient(to right, rgb(244, 123, 38), rgb(238, 48, 108));
    width: 50%;
    border-radius: 0px 0px 10px 10px;
    padding: 17px;
    margin-left: 0px;
    margin-top: -10px;
    padding-top: 28px;
}



.product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}

.product-content-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    /* margin-top: 14px; */
    position: relative;
    width: 100%;
}

.product-content-wrapper-inner-div-one {
    width: 25%;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px;
    background: #ffffff;
}

.product-content-wrapper-inner-div-two {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin-left: 5px;
}

.product-content-wrapper-inner-div-two h4 {
    font-size: 12px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 6px;
    margin-top: 0px;

}

.product-content-wrapper-inner-div-two p {
    margin: 6px;
    font-size: 11px;
}

.ViewAll-Items-wrapper button {
    background-color: rgb(50 89 149) !important;
    border: none !important;
    color: white !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    width: 95%;
    padding: 6px 9px;
    border-radius: 6px;
}

.ViewAll-Items-wrapper {
    margin-top: 15px;
}


/* ===================Landing_page_header_css=================================================================================================================================================================================================================================================== */

.Landing_page_Header_image_wrapper img {
    width: 44%;
    margin: 25px 0;
}

h1.HomePage-Main-Heading-text {
    width: 90%;
    margin: auto !important;
    font-size: 45px;
    line-height: 47px;
    font-weight: bold;
}

.HomePage-Main-Heading-text span {
    color: #EE0000;
}

.HomePage_slider_div-class {
    background-repeat: no-repeat;
    height: 244px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: baseline;
    background-size: 332px !important;
    background-position: right;
    margin-top: 65px;
}

.HomePage_slider_content_div_content {
    margin-bottom: 0rem;
    text-align: left;
    margin-left: 35px;
}

.HomePage_slider_content_div_content label {
    background-color: rgb(75, 79, 91);
    color: white;
    padding: 4px 13px;
    border-radius: 44px;
    text-transform: uppercase !important;
    font-weight: 600;
}

.HomePage_slider_content_div_content h4 {
    margin-top: 30px !important;
    font-size: 30px;
    width: 100%;
    line-height: 30px;
    text-align: center;
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 40px;
    width: 100%;

}

.HomePage-Main-Wrapper-Container {
    padding-bottom: 85px;
}

.HomePage-Main-Wrapper-Container .owl-dots {
    margin-top: 15px;
}

.HomePage-Main-Wrapper-Container .owl-theme .owl-dots .owl-dot span {
    width: 15px !important;
    height: 15px !important;
    background-color: var(--theme-primary);
    opacity: 0.1;
}

.HomePage-Main-Wrapper-Container button.owl-prev, .HomePage-Main-Wrapper-Container button.owl-next {
    font-size: 96px !important;
    font-weight: 200 !important;
    margin: 10px !important;
    color: var(--theme-primary) !important;
}

.HomePage-Main-Wrapper-Container button.owl-dot.active span {
    background-color: var(--theme-primary) !important;
    width: 13px !important;
    height: 13px !important;
    opacity: 1 !important;
}

.owl-carousel .owl-item img {
    width: 80% !important;
    margin: auto !important;
    filter: grayscale();
}

button.owl-prev {
    position: absolute;
    left: 10px;
}

button.owl-next {
    position: absolute !important;
    right: 10px !important;
}

.HomePage-Main-Wrapper-Container button.owl-prev:hover, .HomePage-Main-Wrapper-Container button.owl-next:hover {
    background-color: transparent !important;
}

.HomePage_order_now_button {
    padding: 8px 45px;
    color: white;
    border: none !important;
    border-radius: 10px;
    font-size: 28px;
    font-weight: 600;
    margin: 20px 10px !important;
    cursor: pointer;
    position: relative;
    z-index: 1200 !important;
    margin-top: 25px !important;
    background-color: var(--theme-primary);
}

.HomePage_section_two_wrapper img {
    width: 60%;
}

.HomePage_How_It_Works_text {
    font-size: 20px;
    margin-top: 25px !important;
    margin-bottom: 0px !important;
    color: var(--theme-primary);
    font-weight: 600;
}

/* ===================End=================================================================================================================================================================================================================================================== */
/* ===================HomePageSecond=================================================================================================================================================================================================================================================== */

.Shop_toGate_wrapper {
    background-color: transparent;
    /* background-image: url("/public/assets/images/sodexo_cover.png");
    background-repeat: no-repeat;
    background-size: contain; */
    /* border-radius: 7px; */
    /* padding: 25px; */
    /* margin: 20px; */
    /* height: 422px; */
}

.Shop_toGate_wrapper img {
    width: 270px;
    height: auto;
    object-fit: scale-down;
}

.Shop_toGate_wrapper p {
    font-weight: bold;
    margin-top: 10px !important;
}

.Shop_toGate_wrapper h6 {
    font-weight: bold;
    color: var(--theme-primary);
    margin-top: 5px !important;
}

.Shop_toGate_wrapper button {
    border: 3px solid #5FB4D0 !important;
    font-weight: bold;
    border-radius: 7px;
    font-size: 14px;
    padding: 8px 18px;
    margin: 10px !important;
}

.HomePage_How_It_Works_text_second {
    background-color: rgb(75, 79, 91);
    width: 21%;
    margin: auto !important;
    position: absolute;
    color: white !important;
    left: 36%;
    padding: 11px 25px;
    top: -5%;
    font-weight: 400;
    border-radius: 30px;
    font-size: 18px;
}

.HomePage_second_Youtube_content_wrapper {
    position: relative;
    margin-top: 39px;
    margin-bottom: 29px;
}

.HomePage_Youtube_Video {
    width: 100%;
    height: 350px !important;
}

.HomePage_order_now_button_second {
    background-color: rgb(97, 188, 71);
    padding: 7px 34px;
    color: white;
    border: none !important;
    border-radius: 30px;
    font-size: 19px;
    font-weight: 600;
    margin: 20px !important;
    position: absolute;
    right: 30%;
    bottom: -25px;
}

.ytp-chrome-top.ytp-show-cards-title {
    display: none !important;
}

/* ===================End=================================================================================================================================================================================================================================================== */
/* ===================Footer=================================================================================================================================================================================================================================================== */
.Footer_Content_wrapper {
    position: fixed;
    bottom: 0px;
    box-shadow: 0px 0px 10px #00000029;
    display: flex;
    z-index: 1200 !important;
    background-color: var(--white);
    left: 0;
    justify-content: center;
    /* width: 560px; */
    width: 100%;
    /* margin-inline: auto;
    align-items: center; */
    border-radius: 7px 7px 0px 0px;
    padding: 12px 7px;
}

.Footer_Content_wrapper_copy {
    position: fixed;
    bottom: 0px;
    box-shadow: 0px 0px 10px #00000029;
    display: flex;
    z-index: 0 !important;
    background-color: var(--white);
    left: 0;
    width: 560px;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 14px 7px;
}

.Footer_Content_wrapper p {
    font-weight: 500;
    margin-bottom: 0px !important;
    font-size: 15px;
    margin-right: 13px !important;
}

.Footer_Content_wrapper img {
    width: auto;
    height: 20px;

}

/* ===================End=================================================================================================================================================================================================================================================== */

/* ==============================ItemListPage====================================================================================================================================================================================================================================== */
.ItemList_page_Content_wrapper h1, .ItemList_page_Content_wrapper p {
    text-align: left;
    font-weight: bold;
    font-size: 24px;
}

.ItemList_page_Content_wrapper p {
    text-align: left;
    font-size: 15.9px;
}

.ItemList_Page_Logos_Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 16px 12px;
    border-radius: 10px;
}

.ItemList_Logo_div_one img {
    width: 60%;
}

.ItemList_Logo_div_two img {
    width: 100%;
}

.Logos_Below_div_wrapper {
    background-color: rgb(97, 188, 71);
    color: white;
    width: 70%;
    margin: auto;
    padding: 7px;
    border-radius: 0px 0px 10px 10px;
}

.Logos_Below_div_wrapper p {
    font-weight: 500;
    font-size: 13px;
}

.ItemListMain-Wrapper .HomePage-Shop-To-Gate-Wrapper {
    padding: 20px;
    /* margin: 19px; */
    /* margin-bottom:10px; */

}

.ItemList-Shop-To-Gate-Wrapper-div-two {
    text-align: left;
    padding-left: 30px;
}

.ItemList-Shop-To-Gate-Wrapper-div-two p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}

.ItemList-Shop-To-Gate-Wrapper-div-one {
    /* padding-left: 20px; */
}

.ItemsListPage-ItemsList-containers,
.cart__item__list__container {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    /*  padding: 9px 19px; */
    align-items: center;
    padding-inline: 10px;
    /* margin-bottom:30px; */
    margin-bottom: 12px;
    padding-top: 1rem;
}

.ItemsListPage-ItemsList-containers,
.cart__item__list__container {
    border-bottom: 2px solid #ddd;
    padding-bottom: 12px;
}

.ItemsListPage-ItemsList-containers:first-of-type,
.cart__item__list__container:first-of-type {
    padding-top: 0px;
}

.ItemsListPage-ItemsList-containers:last-of-type,
.cart__item__list__container:last-of-type {
    border-bottom: none;
    margin-bottom: 0px !important;
    padding-bottom: 0px
}

.cart__item__list__container:nth-last-of-type(2) {
    border-bottom: 0.25rem solid var(--theme-primary);
    margin-bottom: 0px !important;
    padding-bottom: 12px
}

.ItemsListPage-ItemsList-containers .product-images-class,
.cart__item__list__container .product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}

.ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two h4,
.cart__item__list__container .product-content-wrapper-inner-div-two h4 {
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 16px;
}

.ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two p,
.cart__item__list__container .product-content-wrapper-inner-div-two p {
    margin: 6px;
    font-size: 12px;
}

.ItemsListPage-ItemsList-Counter-wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 66%;
    align-self: center;
}

.ItemsListPage-ItemsList-Counter-wrapper_Two {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    align-self: center;
    margin: auto !important;
}

.ItemList_Page_Next_button {
    background-color: var(--theme-primary) !important;
    color: white !important;
    border: none;
    padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 7px;
    cursor: pointer;
    position: relative;
}

.ItemList_Page_Next_button svg {
    position: absolute;
    top: 10px;
    right: 20px;
}

.Details_page_disabled_button svg, .Details-page-Nextbutton-wrapper button svg {
    position: absolute;
    top: 15px;
    right: 20px;
}

.ItemsListPage-ItemsList-wrapper-whole {
    margin-top: 20px;
}

.ItemsListPage-ItemsList-wrapper-whole h2 {
    color: #EE0000;
    font-size: 25px !important;
    text-align: left !important;
    font-weight: 700 !important;
}

.ItemsListPage-ItemsList-Counter-input {
    background: transparent;
    border: none;
    width: 100%;
    color: rgb(17, 43, 82);
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    /* padding-left: 14px;
    padding-right:25px; */
}

.ItemsListPage-ItemsList-Counter-input:focus {
    outline: none;
}

.ItemsListPage-ItemsList-Counter-btn {
    background: #ffffff;
    border: none;
    padding: 0px 10px;
    font-weight: 800;
    font-size: 20px;

    color: var(--theme-primary);
}

.ItemsListPage-ItemsList-Counter-btn_Two {
    background: #ffffff;
    border: 1px solid rgb(221 219 219);
    padding: 0px 10px;
    font-weight: 600;
    font-size: 20px;
    color: rgb(17, 43, 82);
    border-radius: 10px;
}

.ItemListpage-ViewCart-wrapper {
    background-color: rgb(21, 64, 129);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding:0px 34px;*/
    bottom: 0;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 560px;
}

.ItemList_page_Content_wrapper p {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal !important;
}

.ItemListpage-ViewCart-wrapper p {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    cursor: pointer;
    margin: 15px 25px;
}

.ItemListpage-ViewCart-wrapper-p-two {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    color: white !important;
    text-decoration: none !important;
}

.ItemListpage-ViewCart-wrapper-p-two a {
    Color: white !important;
    text-decoration: none !important;
}

.ItemListpage-ViewCart-wrapper-p-two span {
    margin-left: 10px;
}

.ItemList_Image_content {
    position: absolute;
    top: 10px;
    left: 20px;
    color: white;
    font-weight: bold;
}

.ItemList_page_Single_Item {
    max-width: unset !important;
    width: 47.5%;
    margin: 0px !important;
    margin-bottom: 20px !important;
}

.Item_Individual_Image {
    padding-top: 36px;
    padding-bottom: 10px;
}

.Item_Individual_btn_content {
    padding-bottom: 20px !important;
    padding: 10px !important;
}

.ItemList_Page_2_List_wrapper_Main_Whole {
    grid-column-gap: 15px;
}

/* ==============================End===================================================================================================================================================================================================================================== */
/* ==============================storelist page===================================================================================================================================================================================================================================== */
.Storelist-container-wrapper {
    margin-top: 15px;
    margin-bottom: 50px;
    padding: 20px;
}

.Storelist-container-wrapper h3 {
    text-align: left;
    font-size: 22px;
    font-weight: bold;
    /* color: var(--theme-primary) */
}

.Congratulations_page_QRcode_pin_wrapper h3 {
    font-size: 18px;
    font-weight: bold;
}

.Congratulations_page_QRcode_pin_wrapper {
    margin-top: 23px;
}

.Congratulation_page_otp_input {
    justify-content: center;
    margin-top: 20px;
}

.Congratulation_page_otp_input input {
    border-radius: 5px;
    font-weight: 800;
    border: 1px solid grey;
}

.Storelist_container_inner_item_wrapper {
    display: grid;
    align-items: start;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    margin-top: 20px;
    /* grid-template-columns: 25% 70%; */
    grid-template-columns: 30% 62%;
}

.Storelist_container_inner_item_wrapper img {
    margin-right: 10px;
    width: 100%;
    margin-left: 0px;
}

.Storelist_page_comingsoon_img img {
    width: 20% !important;
    position: absolute;
    left: 0;
    top: 0;
}

.Storelist_container_inner_item_wrapper_content_div {
    /* text-align: left; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.Storelist_container_inner_item_wrapper_content_p {
    color: var(--theme-primary)
}

.Storelist_container_inner_item_wrapper_content_div h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-align: start;
}

.Storelist_container_inner_item_wrapper_content_div h6 {
    font-size: 16px;
}

.Storelist_container_inner_item_wrapper_content_div h6:last-of-type {
    /* color: var(--theme-primary); */
    font-size: 12px;
    margin-top: 3px !important;
}

.Storelist_container_inner_item_wrapper_content_div p {
    font-size: 12px;
    line-height: 14px;
    /* color:#777777; */
    /* width: 241px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
}

.Storelist_container_inner_item_wrapper_content_div svg {
    margin-right: 5px !important;
    width: 20px;
}

.inactive_store {
    /* opacity: 0.5; */
    filter: grayscale();
}

.inactive_store_with_drop_shadow {
    filter: grayscale() drop-shadow(0 0 6pt rgba(0, 0, 0, 0.3));
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Productlist page===================================================================================================================================================================================================================================== */
.Product_page_outer_div {
    /* display: flex; */
    /* justify-content: center; */
    /* padding: 10px 34px 23px 20px; */
    width: 560px;
    margin: auto;
    /* background-image:url('/public/assets/images/SubwayBackground.jpg'); */
    background-color: lightgrey;
    padding: 85px 20px 25px;
    background-repeat: no-repeat;
    background-size: 100%;
    /* background-position: center bottom; */
}

.Product_page_inner_div_one {
    display: flex;
}

.Product_page_inner_div_one h3 {
    font-weight: bold;
    font-size: 19px;
}

.Product_page_inner_div_one h5 {
    color: var(--theme-primary);
    font-size: 15px;
    font-weight: bold;
}

.Product_page_outer_div_inner_wrapper {
    background-color: rgba(255, 255, 255, 0.9);
    align-items: center;
    align-self: end;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
    width: 100%;
}

.Product_page_header_upper {
    display: flex;
    align-items: end;
    justify-content: center;
    /* background-color: var(--white); */
    border-radius: 10px;
    padding: 3px 23px;
    position: relative;
    /* width: 100%; */
    height: 100%;
}

.Product_page_header_upper img {
    width: 175px;
}

.Product_page_header_wrapper p {
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    padding-left: 10px;
    width: 100%;
    margin: auto !important;
    line-height: 14px;
}

.Product_page_inner_div_two {
    display: none;
}

.Product_page_header_wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    margin-right: 63px;
    background-color: var(--white);
    z-index: 1 !important;
    padding-bottom: 15px;
}

.Productlist_page_accordian_heading {
    color: var(--theme-primary);
    text-transform: capitalize !important;
    font-weight: bold;
    font-size: 16px;
    border-bottom-style: solid;
    border-bottom-width: 0.25rem;
    border-bottom-color: rgba(var(--theme-primary-hex), 0.2);
    /* border-bottom: 4px solid var(--theme-primary); */

}

.map-wrapper {
    height: 50%
}

.Product_page_header_wrapper_two {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    margin-right: 63px;
    background-color: var(--white);
    z-index: 0 !important;
}

.Productlist_page_content_wrapper {
    display: flex;
    flex-direction: column;
    /* padding-top: 200px; */
    /* padding-top: 240px; */
    /* padding-bottom: 100px; */
}

.Productlist_page_menu_wrapper {
    padding: 17px 28px;
    display: flex;
    overflow-x: scroll;
}

.Productlist_page_Navigation_wrapper {
    flex-wrap: wrap !important;
    position: relative !important;
    display: flex !important;
    text-align: start !important;
    height: 70px !important;
    background: transparent !important;
    /* overflow-x:scroll !important;  */
    flex-direction: column !important;
    padding-bottom: 27px !important;
    overflow-y: hidden !important;
    font-family: 'Poppins', sans-serif !important;
}

.Productlist_page_menu_wrapper h3 {
    margin-bottom: 20px !important;
}

.Productlist_page_individual_product {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.Productlist_page_productlist_wrapper {
    padding: 14px 20px;
    /* overflow-y: scroll; */
    /* height: 460px; */
    padding-top: 0px;
    /* height: calc(100vh - 315px); */
    overflow: auto;
}

.Productlist_page_productlist_wrapper>div {
    box-shadow: none !important;
}

.Productlist_page_productlist_wrapper>div>div {
    padding: 0;
}

.Productlist_page_individual_product_content_wrapper {
    text-align: left;
    /* margin:15px; */
    width: 65%;
}

.goog-te-combo {
    position: relative;
}

.thumbnail-image {
    width: 20px !important;
    height: auto !important;
    margin-right: 5px;
}

.goog-te-combo option:first-of-type::before, .language_selector_en::before {
    content: '';
    background-image: url('../../public/assets/images/2560px-Flag_of_the_United_States.svg.png');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    /* Adjust the padding to create space for the flag image */
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;

}

.goog-te-combo option:last-of-type {
    background-image: url('../../public/assets/images/Flag_of_France.png');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    /* Adjust the padding to create space for the flag image */
}

.Productlist_page_individual_product_content_wrapper p {
    font-size: 1rem;
    line-height: 14px;
    margin-top: 6px !important;
    color: #777777;
}

.Productlist_page_individual_product_content_wrapper h2 {
    font-size: 1rem;
    line-height: 20px;
    font-weight: bold;
}

.Productlist_page_individual_product_image_wrapper {
    box-shadow: 0px 0px 6px #0000001a;
    border-radius: 10px;
    width: 35%;
    padding: 7px;
    position: relative;
    margin-left: 20px;
}

.Productlist_page_individual_product_image_wrapper img {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 20px;
}

.Productlist_page_rating_div {
    display: none;
    align-items: center;
    margin-bottom: 11px;
    justify-content: flex-start;
    margin-top: 0px;
}

.cart__product__list__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 50%; */
    width: 80px;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    background: white;
}

.cart__product__list__container {
    display: flex;
    justify-content: end;
    gap: 4px;
    text-align: end;
    font-weight: bold;
}

.cart__order__price {
    display: flex;
    justify-content: end;
    width: 40%;
    align-items: center;
}

.Productlist_product_counter_wrapper_wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -20px;
    width: 100%;
}

.Productlist_product_counter_wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    /* margin-bottom: -10px !important; */
    background: white;
    width: max-content;
    align-items: center;
}

.Productlist_page_menu_icon_wrapper {
    background-color: var(--white);
    color: var(--theme-primary);
    position: fixed;
    bottom: 76px;
    right: 33px;
    border-radius: 100%;
    padding: 25px 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 1200 !important;
    box-shadow: 0px 0px 10px #00000046;
    display: none;
}

.cart__empty__message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: bold;
}

.cart__clear {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    /* top: 4px; */
    /* right: 10px; */
    width: max-content;
    font-size: 0.75rem;
    padding: 0.5rem;
    font-weight: 700;
    border-radius: 10px;
    /* background-color: #80808078; */
    background-color: var(--theme-primary);
    color: white;
    cursor: pointer;
}

.cart__clear__icon {
    color: white;
    width: 15px;
    height: 15px;
}

.cart__order__delete {
    position: absolute;
    top: -15px;
    left: -15px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    border-radius: 20px;
    background-color: var(--theme-primary);
    /* color: #686565; */
}

.cart__order__delete__icon {
    width: 100%;
    height: 100%;
    color: white;
    /* color: red; */
}

.cart__product__list__wrapper button,
.Productlist_product_counter_wrapper button {
    width: 100%;
    background: transparent;
    border: none;
    color: var(--theme-primary);
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
    font-family: "Poppins";
}

.ProductList_page_next_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-primary);
    color: white;
    font-size: 16px;
    text-transform: capitalize !important;
    font-weight: bold;
    position: fixed;
    width: 525px;
    left: 0;
    bottom: 55px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 10px;
}

.ProductList_page_next_button_wrapper_inner {
    position: relative;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    /* grid-template-columns: 50% 50%; */
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.ProductList_page_next_button_wrapper_inner div p {
    font-weight: 700;
    font-size: 16px;
}

.Sorry_div_wrapper {
    text-align: center;
    padding: 20px 20px;
}

.Sorry_div_wrapper h4 {
    color: #D73B1E;
    font-size: 30px;
}

.Sorry_div_wrapper_p {
    font-size: 16px;
}

.NoStore_available_text_div {
    margin-top: 20px;
    display: flex;
    background-color: rgba(64, 180, 208, 0.1);
    padding: 50px 20px;
    text-align: center;
    border-radius: 7px;
}

.NoStore_available_text_div img {
    margin-bottom: 15px;
}

.NoStore_available_text_div h4 {
    font-size: 16px;
    display: flex;
    flex-grow: 1;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

.Sorry_div_wrapper_next_button {
    background-color: var(--theme-primary);
    border: none;
    color: white;
    font-size: 19px;
    font-weight: 600;
    padding: 10px 26px;
    border-radius: 10px;
    margin-top: 23px !important;
}

.Sorry_div_wrapper_inner_div {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-top: 0px;
    padding-bottom: 10px;
    cursor: pointer;
}

.Productlist_page_individual_product_content_wrapper p.Productlist_page_price_p {
    color: var(--theme-primary) !important;
    margin-top: 4px !important;
    font-size: 14px !important;
    /* margin-top: 4px; */
    font-weight: 700;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================ItemListTwo===================================================================================================================================================================================================================================== */
.ItemList_Page_2_List_wrapper_Main_Whole {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================DetailsPage===================================================================================================================================================================================================================================== */
.DetailsPage-Main-wrapper {
    /* margin-top: 24px; */
    padding: 25px;
    padding-top: 100px;
    position: relative;
    padding-bottom: 100px;
    background-color: #F1F0F5;
    min-height: 100dvh;
    text-align: left;
    height: 100%;
}

.mapboxgl-ctrl-bottom-right {
    display: none !important;
}

.mapboxgl-ctrl-bottom-left {
    display: none !important;
}

.DetailsPage-Map-wrapper {
    /* margin-top: 24px; */
    /* padding: 25px; */
    padding-top: 58px;
    position: relative;
    padding-bottom: 140px;
    background-color: #F2F2F2;
    min-height: 100dvh;
    text-align: left;
}

.DetailsPage-content-wrapper {
    background: #fff;
    padding: 1.5rem 0px;
    /* margin: 32px; */
    border-radius: 10px;
    margin-top: 17px;
    position: relative;
    /* box-shadow: 0px 0px 17px #00000029; */
}

.DetailsPage-content-wrapper .Mui-selected::after {
    content: "";
    border: none !important;
}

.DetailsPage-content-wrapper .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
    border: 2px solid var(--theme-primary) solid rgb(221 219 219) !important;
    border-radius: 30px !important;
    padding: 22px 9px;
    margin: 4px !important;
    min-width: 65px !important;
}

.DetailsPage-content-wrapper .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    background-color: rgb(97, 188, 71) !important;
    border-radius: 30px;
    padding: 0px 10px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif !important;
    color: white;
}

.DetailsPage-content-wrapper-inner {
    /* margin-top: 27px; */
    margin-bottom: 10px;
}

.Details_page_input_wrapper input {
    padding: 11.5px 14px;
    font-family: 'Poppins', sans-serif !important;
}

.DetailsPage-content-wrapper-inner_h3 {
    text-align: left;
    color: var(--theme-primary);
    font-size: 17px;
    font-weight: bold;
}

.DetailsPage-content-wrapper .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
    text-align: left !important;
    font-weight: 900;
    font-size: 17px;
    color: rgb(97, 188, 71);
    /* padding-left:20px; */
}

.DetailsPage-Main-wrapper h2 {
    text-align: left;
    /* padding-left:33px; */
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: bold;
}

.Details-page-Nextbutton-wrapper button {
    background-color: var(--theme-primary);
    color: white !important;
    border: none;
    padding: 10px 20px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 7px;
    cursor: pointer;
    position: relative;
}

.Details-page-Nextbutton-wrapper {
    /* margin-top: 12rem; */
    position: fixed;
    bottom: 52px;
    background-color: #fff;
    padding: 12px;
    border-radius: 7px 7px 0 0;
    width: 536px;
    transform: translate(-50%);
    left: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: grid;
}

.Details_page_disabled_button {
    background-color: white !important;
    color: #939393 !important;
    border: none !important;
    padding: 10px 20px !important;
    font-size: 20px !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    border-radius: 7px !important;
    cursor: pointer !important;
    position: relative !important;
    text-align: center;
}

.Details_page__button {
    background-color: white !important;
    color: var(--theme-primary) !important;
    border: none !important;
    padding: 10px 20px !important;
    font-size: 20px !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    border-radius: 7px !important;
    cursor: pointer !important;
    position: relative !important;
    text-align: center;
}

.DetailsPage_DeliveryLocation_Wrapper {
    display: flex;
    flex-wrap: wrap !important;
}

.detailspage-content-label {
    text-align: left !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    color: #EE0000 !important;
    font-family: 'Poppins', sans-serif !important;
}

.Details_page_selected_deivery_location_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    /* padding: 17px; */
    position: absolute;
    bottom: 150px;
    width: 450px;
    padding: 15px 30px;
    transform: translate(-50%, 0);
    left: 50%;
    border-radius: 5px;
}

.Details_page_selected_deivery_location_wrapper_cartpage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 17px;
    position: absolute;
    bottom: 105px;
    width: 80%;
    padding: 5%;
    left: 5%;
    border-radius: 5px;
}

.Details_page_selected_deivery_location_wrapper p {
    color: var(--theme-primary) !important;
    font-weight: bold;
    font-size: 20px;
}

/* ==============================End===================================================================================================================================================================================================================================== */
/* ==============================BillDetails===================================================================================================================================================================================================================================== */
.Bill_Details_content_itemcount {
    color: var(--theme-primary);
    font-size: 14px;
}

.BillDetails_page_BillTotal_wrapper h3 {
    color: var(--theme-primary);
    text-align: start;
    font-size: 20px;
    font-weight: bold;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield !important;
}

.BillDetails_page_BillTotal_wrapper {
    margin: 0px;
    margin-top: 30px;
    padding-bottom: 100px;

}

.loader__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
}

.loader__icon {
    color: var(--theme-primary) !important;
    fill: var(--theme-primary);
}

.location__name__popup {
    padding: 5px 10px;
    cursor: pointer;
}

.mapboxgl-popup-content {
    padding: 0px !important;
}

.mapboxgl-popup-tip {
    border-width: 5px !important;
}

.BillDetails_Header_title_wrapper h6 {
    color: var(--theme-primary);
    font-weight: bold;
    font-size: 15px;
}

.BillDetails_Header_title_wrapper h3 {
    font-weight: bold;
    font-size: 19px;
}

.BillDetails_item_total_wrapper {
    margin-bottom: 15px;
    margin-top: 15px;
    display: grid;
    grid-template-columns: 70% 30%;
}

.BillDetails_item_totalprice_wrapper {
    margin-bottom: 15px;
    margin-top: 15px;
    display: grid;
    grid-template-columns: 70% 30%;
    font-weight: bold;
}

.BillDetails_item_total_wrapper p {
    /* font-weight:bold !important; */
    font-size: 16px !important;
    color: black !important;
}

.BillDetails_item_total_wrapper h4 {
    font-weight: 500;
    font-size: 14px !important;
    color: black !important;
}

.BillDetails_item_totalwhole_wrapper {
    /* padding-top: 15px; */
    /* border-top: 1px solid lightgrey; */
}

.BillDetails_item_topay_wrapper {
    /* border-top:1px solid lightgrey; */
    color: var(--theme-primary);
    padding: 20px 20px 0px 20px;
    display: grid;
    grid-template-columns: 60% 40%;
}

.BillDetails_item_topay_wrapper p {
    font-weight: bold;
    font-size: 14px !important;
}

.BillDetails_item_topay_wrapper h4 {
    font-weight: 500 !important;
    font-size: 17px !important;
}

.Billpage_proceed_tonext_btn_wrapper {
    position: fixed;
    bottom: 40px;
    background-color: var(--theme-primary);
    display: grid;
    grid-template-columns: 50% 50%;
    width: 560px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 3px 3px 6px 3px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 3px 6px #00000029;
    align-items: center;
    z-index: 99;
}

.BillPage_proceed_to_btn {
    background-color: white;
    border: none;
    color: var(--theme-primary);
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    border-radius: 6px;
    cursor: pointer;
}

.BillPage_avail_discount_btn {
    background-color: var(--theme-primary);
    border: none;
    color: white;
    width: max-content;
    font-size: 19px;
    font-weight: bold;
    padding: 5px 25px;
    border-radius: 10px;
    cursor: pointer;
}

.Billpage_proceed_tonext_btn_wrapper h3 {
    color: var(--theme-primary);
    font-weight: bold;
    text-align: start;
    padding-left: 14px;
}

/* ==============================End===================================================================================================================================================================================================================================== */
/* ==============================CartPage===================================================================================================================================================================================================================================== */

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner div {
    text-align: left;
    width: 100%;
    padding: 0;
    margin-top: 3px;
    justify-content: center;
    margin-left: 12px;
}

.CartPage-Main-wrapper .detailspage-content-label {

    font-size: 13px;
    text-align: left !important;
}

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner p {
    margin: 0px;
    margin-top: 6px;
    /* padding-left:20px; */
}

.CartPage-Main-wrapper h2 {
    text-align: start;
    /* padding-left: 33px; */
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: bold;
}

.CartPage-Main-wrapper p {
    font-size: 19px;
    text-align: start;
    font-weight: bold;
    color: var(--theme-primary)
}

.CartPage-Main-wrapper {
    /* margin-top: 36px; */
    /* height: auto; */
    min-height: 100dvh;
    height: 100%;
    background-color: #F1F0F5;
}

.CartPage-Main-wrapper_h5 {
    text-align: left;
    font-weight: 500;
    font-size: 15px;
}

.CartPage-Main-wrapper .ItemsListPage-ItemsList-Counter-wrapper {
    display: flex;
    justify-content: end !important;
    align-items: center;
    width: 100%;
    margin-top: 17px;
    margin-left: 5rem;

}

.CartPage-Main-wrapper .product-content-wrapper-inner-div-one {
    width: 130px;
}

.CartPage-Main-wrapper .ItemsListPage-ItemsList-containers .product-images-class,
.CartPage-Main-wrapper .cart__item__list__container .product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}

.CartPage-Main-wrapper .product-content-wrapper {
    /* width: 50%; */
}

.CartPage-Main-wrapper .CartPage-Product-wrapper-h2 {
    /* padding-left: 11px; */
    font-size: 18px;
    text-align: left;
}

.CartPage-Product-wrapper-h2 {
    color: var(--theme-primary);

}

.CartPage-amount-padi-wrapper {
    background: rgb(97, 188, 71);
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 13px 19px;
    /*   margin: 32px; */
    margin-top: 0px;
    border-radius: 0px 0px 14px 13px;
}

.CartPage-Main-wrapper .DetailsPage-content-wrapper {
    background: #fff;
    /* padding: 20px; */
    margin: 0;
    border-radius: 10px;
    margin-top: 17px;
    margin-bottom: 70px;
}

.CartPage-content-wrapper {
    padding: 20px;
    /* margin: 20px; */
    background-color: white;
    margin-top: 23px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 17px #00000029; */
}

.CartPage-amount-padi-wrapper p {
    margin: 6px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
}

.CartPage-amount-padi-wrapper h4 {
    margin: 0px
}

.CartPage-Main-wrapper .ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two p,
.CartPage-Main-wrapper .cart__item__list__container .product-content-wrapper-inner-div-two p {
    font-size: 16px;
}

.Cart_page_Resend_button_wrapper {
    text-align: center;
    margin-top: 32px;
}

.Cart_page_Resend_button_wrapper p {
    margin: 11px;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 20px;
}

.Cart_page_Resend_button_wrapper button {
    border: none;
    background-color: transparent;
    color: var(--theme-primary);
    border-radius: 0px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid var(--theme-primary);
    text-transform: uppercase !important;
}

.Cart_page_otp_input {
    justify-content: center;
    margin-top: 22px;
}

.Cart_page_otp_input input:focus-visible {
    border: 2px solid var(--theme-primary);
    outline: var(--theme-primary);
}

.Cart_page_otp_input input {
    width: 35px !important;
    height: 35px !important;
    border: 2px solid #dddddd;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    margin: -6px;
    -moz-appearance: none;
    appearance: none;
}

.Cart_page_selected_deivery_location_wrapper {
    text-align: center !important;
    margin-top: 30px;
}

.Cart_page_selected_deivery_location_wrapper span {
    color: #EE0000;
}

.checkout_page_card_element {
    border-bottom: 1px solid grey;
}

.checkout_page_card_element_wrapper {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
}

.checkout_page_card_element_wrapper_div {
    /* margin:10px; */
    padding-bottom: 20px;
}

.checkout_page_card_element_wrapper_div p {
    text-align: start;
    font-size: 14px;
    color: grey;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Congratulation page===================================================================================================================================================================================================================================== */

.Congratulation-content-div-one-wrapper {
    /* background-color: rgb(119,162,47); */
    /* background-color: #F5F5F5; */
    background-color: var(--theme-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    gap: 8px;
}

.Congratulation-content-div-one-wrapper-inner-two {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    /* padding-left:20px; */
}

.Congratulation-content-div-one-wrapper-inner-two h1 {
    font-size: 34px;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    /* text-align: start; */
    font-weight: bold;
}

.Congratulation-content-div-one-wrapper-inner-two p {
    font-size: 23px;
    margin: 0px;
    font-weight: bold;
    color: var(--theme-primary)
}

.Congratulation-content-div-one-wrapper-inner-one {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: auto; */
}

.Congratulation-content-div-one-wrapper-inner-one img {
    width: 94%;
    height: auto;
    border-radius: 100%;
    object-fit: fill;
    padding: 0px;
    margin-top: 0px;
    margin-left: 0px;
}

.Congratulation-page-order-wrapper-below-QR {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    margin: 1.5rem;
    justify-content: space-between;
    /* border-bottom: 3px solid var(--theme-primary); */
}

.Congratulation-page-order-wrapper-below-QR h5 {
    margin: 0px;
    color: var(--theme-primary);
    font-family: 'Poppins', sans-serif !important;
    font-weight: bold;
    font-size: 14px;

}

.Congratulations_page_QRcode_pin_wrapper {
    padding: 0px 10px;
}

.Congratulation-page-order-wrapper-below-QR p {
    margin: 0px;
    font-weight: 500;
    font-size: 13px;
    /* text-align: left !important; */
}

.QR-Code-wrapper-whole-inner-one h4 {
    font-size: 18px;
    margin: 0px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    line-height: 18px;
}

.QR-Code-wrapper-whole-inner-one p {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
}

.QR-Code-wrapper-whole-inner-one {
    padding: 0px 26px;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Congratulation-page-invoice-wrapper-table-whole {
    width: 92%;
    margin: 20px;
    border-collapse: collapse;
    margin-top: 5px;
    border-bottom: 1px solid lightgrey;
}

.Congratulation-page-invoice-wrapper-table-whole thead {
    border-bottom: 2px solid var(--theme-primary) solid rgb(97, 188, 71) !important;
    color: rgb(97, 188, 71) !important;
    padding-bottom: 20px !important;
}

.QR-Code-main-inner img {
    width: 300px !important;
    height: 300px !important;
    background: #FFF;
    padding: 10px;
    margin-top: 19px;
}

.Congratulation-page-invoice-wrapper-table-whole tbody tr td {
    padding: 9px;
    padding-left: 0px;
    font-size: 13px;
    font-weight: 600;
}

.Congratulation-page-invoice-wrapper-table-whole tbody tr td span {
    font-weight: bolder;
}

.Congratulation-page-subtotal-wrapper, .Congratulation-page-total-wrapper {
    text-align: right;

}

.Congratulation-page-subtotal-wrapper {
    margin: 30px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.Congratulation-page-subtotal-wrapper h4 {
    margin: 4px;
}

.Congratulation-page-subtotal-wrapper h4 span {
    color: black;
    font-weight: 500;
}

.Congratulation-page-total-wrapper {
    margin: 30px;
    /* background: #DCE1E7; */
    font-weight: 700;
    color: var(--theme-primary) !important;

    margin-top: 0px;
}

.Congratulation-page-total-wrapper p {
    margin: 0px;
}

.Congratulation-page-total-wrapper p span {
    font-weight: 500;
}

.Congratulation-Main-wrapper {
    /* padding-bottom: 50px; */
    padding-top: 55px;
    background-color: #F1F0F5;
    height: calc(100dvh - 0%);
}

.Congratulation-content-div-one-wrapper-cancelled {
    background-color: var(--Bgred);
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    gap: 8px;
}

.Congratulations_page_QRcode_pin_wrapper h3 {
    font-size: 18px;
    font-weight: bold;
}

.Congratulations_page_QRcode_pin_inner {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 14px;
    padding: 0px 28px;
}

.Congratulations_page_QRcode_pin_inner button {
    background: var(--theme-primary);
    color: white;
    border: none;
    border-radius: 50px;
    padding: 9px 27px;
    font-size: 15px;
    font-weight: bold;
}

.Congratulations_page_QRcode_pin_inner p {
    color: var(--theme-primary);
    font-weight: bold;
}

.Congratulation-page-invoice-wrapper {
    margin-top: 33px;
}

.Congratulation-page-invoice-wrapper h3 {
    margin: 20px !important;
    text-align: start;
    margin-bottom: 0px !important;
    color: var(--theme-primary)
}

.Congratulations_page_estimated_time_div_grey {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
    color: #fff;
    margin-top: 0px !important;
    margin-right: 0px !important;
    position: fixed;
    bottom: 126px;
    background-color: var(--grey);
    z-index: 1200 !important;
    width: 560px;
    height: 65px;
    padding: 13px 25px;
    border-radius: 7px;
}

.Congratulations_page_estimated_time_div p, .Congratulations_page_estimated_time_div_grey p {
    font-weight: bold;
    font-size: 15px;
    /* margin-left: 18px !important; */
    text-align: start;
}

.Congratulations_page_estimated_time_div {
    position: fixed;
    bottom: 40px;
    background-color: var(--theme-primary);
    display: grid;
    grid-template-columns: 50% 50%;
    width: 560px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 3px 3px 6px 3px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 3px 6px #00000029;
    align-items: center;
    z-index: 99;
}

.Congratulations_page_estimated_time_div h3, .Congratulations_page_estimated_time_div_grey h3 {
    background-color: var(--white);
    color: var(--theme-primary);
    font-weight: bold;
    /* padding: 16px 34px; */
    font-size: 20px;
    padding: 10px;
    border-radius: 7px;
}

.Toastify__toast-container--top-right {
    z-index: 1200 !important;
}

.Congratulations_page_QRcode_pin_wrapper h4 {
    font-size: 14px;
    font-weight: bold;
}

.Congratulations_item_total_wrapper h4 {
    font-weight: 500 !important;
    font-size: 14px !important;
    color: black !important;
}

.Congratulations_item_total_wrapper p {
    font-weight: bold !important;
    font-size: 14px !important;
    color: var(--theme-primary) !important;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Login===================================================================================================================================================================================================================================== */
.Login-page-form {
    width: 25%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 4px 33px 12px #d6d6d6;
    padding: 25px;
    padding-top: 37px;
    border-radius: 8px;
}

.LoginPage-Main-wrapper {
    height: 700px;
    align-items: center;
    align-self: center;
    vertical-align: middle;
    background-color: white;
    display: flex;
    width: 100%;
}

.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root {
    width: 95%;
    margin: auto;
}

.Login-page-form-logo-image {
    width: 48%;
}

.Loginpage-main-form-inner-div {
    margin-top: 25px !important;
}

.Login-page-form p {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: rgb(21, 64, 129);
    font-weight: 600;
    margin-top: 4px !important;
}

.Login-page-form h6 {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-size: 15px;
}

.Loginpage\=-main-form {
    margin: 24px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 13px;
    margin-top: 29px;
}

.Login-page-form .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: '#a89f9f' !important;
    letter-spacing: 0px !important;
}

.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: black;
    font-weight: 500;
}

.Loginpage-btn-wrapper {
    background-color: rgb(45 87 152);
    padding: 10px;
    border-radius: 7px;
}

.Login-page-form button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Loginpage-btn-wrapper-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: #ffffff !important;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif !important;
    width: 100%;
}

.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error {
    font-weight: 400;
    font-size: 10px;
    padding-left: 39px;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Checkout===================================================================================================================================================================================================================================== */
.Checkout-StripeMain-wrapper {
    padding: 30px;
    height: 580px;
    padding-top: 92px;
}

.Checkout-StripeMain-wrapper h1 {
    text-align: left;
    color: var(--theme-primary);
    font-size: 35px;
    margin: 0px;
    letter-spacing: -2px solid var(--theme-primary) !important;
    /* padding-left: 11px; */
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    display: none;
}

.Checkout-StripeMain-wrapper h3 {
    text-align: left;
    /* padding-left: 18px; */
    /* margin-top: 4px; */
    font-weight: bold;
    font-size: 20px;
}

.Checkout-StripeMain-wrapper .FormGroup {
    background: transparent;
    box-shadow: none;
    padding: 5px;
    border: 1px solid black;
}

.Checkout-StripeMain-wrapper .FormRow {
    border-top: none !important;
}

.CheckoutStripe-content-wrapper {
    background-color: #ffffff !important;
    padding: 20px !important;
}

/* ==============================End===================================================================================================================================================================================================================================== */
/* =================================SelectDeliveryLocation================================================================================================================================================================================================================================== */
.Select_delivery_page_inner_wrapper {
    text-align: left;
    padding-top: 15px;
    padding: 15px;
    background-color: white;
    padding: 15px;
    /* box-shadow: 0px 0px 17px #00000029; */
    border-radius: 10px;
    margin-bottom: 60px;
    padding-bottom: 40px;
}

.Select_delivery_page_InnerHeading {
    color: rgb(97, 188, 71);
    font-weight: 700;
}

.SelectDelivery_page_error_p {
    color: white;
    text-align: left;
    margin-bottom: 10px !important;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    /* padding-left: 27px; */
}

.p_error {
    color: #d32f2f;
    font-weight: bold;
}

.Select_delivery_location_list_bottomnavigation {
    display: inline-block !important;
    position: relative;
    text-align: center;
}

.Select_delivery_location_delivery_name {
    /* background-color: white;
    border-radius: 30px;
    margin:0;
    padding: 7px 40px;
    font-weight: 500; */
    background-color: white;
    border-radius: 30px !important;
    margin: 0;
    padding: 7px 0px;
    font-weight: 500;
    max-width: 105px;
    width: 105px;
    text-align: center;
}

.Select_delivery_page_next_btn_wrapper {
    position: fixed;
    bottom: 52px;
    background-color: var(--theme-primary);
    padding: 12px;
    border-radius: 10px 10px 0px 0px;
    width: 560px;
    transform: translate(-50%);
    left: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: grid;
}

.delivery_active {
    background-color: #EE0000 !important;
    color: white !important;
}

.delivery_location_page_content_heading {
    color: #EE0000 !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    padding-left: 12px;
}

.Select_delivery_page_inner_wrapper .Mui-selected {
    background-color: var(--theme-primary) !important;
    color: white !important;
    /* border-radius: 30px */

}

.Single_btn_select_delivery_location {
    margin: 0px !important;
    /* border-radius: 30px !important; */
    margin-right: 15px !important;
    font-weight: bold !important;
    padding: 9px 24px !important;
    background-color: white !important;
    margin: 10px !important;
    border: 1px solid lightgrey !important;
    border-radius: 10px !important;
    /* font-size: 24px !important; */
    font-family: 'Poppins' !important;
    color: '';
    height: unset !important;
    white-space: nowrap;
}

.Single_btn_select_delivery_location span {
    font-family: 'Poppins' !important;
    font-weight: bold;
    font-size: 15px;
    transition: none !important;
    line-height: normal;
}

.Select_delivery_locations_list_wrapper {
    /* display: flex;
    flex-wrap: wrap; */
    /* margin-top:10px; */
    font-size: 18px;
    column-gap: 10px;
    row-gap: 10px;
}

.Select_delivery_locations_list_wrapper>div>button {
    /* margin: 15px !important;
    border: 1px solid #dddddd;
    border-radius: 7px;
    padding: 12px 15px !important;
    display: inline-block !important;
    max-width: 80px !important;
    min-width: 80px !important; */
}

.banner__text {
    width: 560px;
}

.Select_delivery_location_delivery_name:hover {
    background-color: rgb(97, 188, 71) !important;
    color: white !important;
}

.Select_delivery_page_concourse_page_heading {
    color: var(--theme-primary);
    text-align: left;
    font-size: 20px;
    margin-top: 25px !important;
    margin-bottom: 12px !important;
    font-weight: bold;
}

/* =====================================End========================================================================================================================================================================================================================================================== */
/* ==============================AdminDashboard===================================================================================================================================================================================================================================== */
.Dashboard-page-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(21, 64, 129);
    color: white;
    padding: 3px 19px;
    border-radius: 10px 10px 0px 0px;
}

.Dashboard-page-tab-header-div-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Dashboard-page-tab-header-div-two div {
    margin: 12px;
}

.Dashboard-page-tab-header-div-one h3 {
    margin: 0px;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
}

.Dashboard-page-tab-header-div-two h3 {
    font-size: 19px;
}

.Dashboard-page-tab-header-div-two p {
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

.Dashboard-page-tab-header-div-one h3, .Dashboard-page-tab-header-div-two p, .Dashboard-page-tab-header-div-two h3 {
    margin: 0px;
}

.AdminDashboard-whole-Main-wrapper {
    padding: 50px;
}

.AdminDashboard-tabs-left-side {
    width: 35%;
    background: #fff;
    margin: 10px;
}

.AdminDashboard-tabs-left-side .css-8je8zh-MuiTouchRipple-root {
    display: none;
}

.AdminDashboard-tabs-right-side {
    width: 60%;
    background: #fff;
    margin: 10px;
}

.dashboard-order-list-wrapper-inner-div-one, .dashboard-order-list-wrapper-inner-div-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px;
    padding: 0px 16px;
}

img.PowredBY_stripe {
    height: 33px;
    margin-top: 30px;
    filter: grayscale(1);
}

.Inner-tab {
    width: 100% !important;
    display: block !important;
    padding: 0px !important;
    text-align: start !important;
    max-width: 100% !important;
    align-items: flex-start !important;
}

.Dashboard-page-seperator-line {
    position: relative;
    width: 70%;
}

.dashboard-order-list-wrapper {
    padding: 14px 0px;
    width: 100%;
    border-bottom: 3px solid rgb(242, 242, 242);
}

.Dashboard-page-seperator-line:before {
    content: '';
    background-color: rgb(210, 213, 205);
    color: rgb(210, 213, 205);
    position: absolute;
    width: 95%;
    height: 3px;
    left: 0px;
}

.Dashboard-page-seperator-line:after {
    content: '.';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: rgb(210, 213, 205);
    position: absolute;
    right: 8px;
    top: -3px;
}

.dashboard-order-list-wrapper-inner-div-one p {
    text-transform: capitalize;
    text-align: center;
    background: rgb(224, 232, 208);
    padding: 0px 14px;
    font-size: 12px;
    padding: 2px solid var(--theme-primary) 16px;
}

.dashboard-order-list-wrapper-inner-div-one h5 {
    color: rgb(21, 64, 129);
    font-size: 14px;
}

.dashboard-order-list-wrapper-inner-div-two p {
    width: 20%;
    text-transform: capitalize;
    font-weight: 600;
}

.dashboard-order-list-wrapper-inner-div-two-p-two {
    text-align: center;
}

.dashboard-order-list-wrapper-inner-div-two-p-one {
    text-align: start;
}

.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .dashboard-order-list-wrapper {
    color: black !important;
    background: rgb(215 220 205);
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    overflow: visible !important;
}

.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:before {
    content: '';
    background-color: black;
    color: rgb(210, 213, 205);
    position: absolute;
    width: 95%;
    height: 3px;
    left: 0px;
}

.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:after {
    content: '.';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: black;
    position: absolute;
    right: 8px;
    top: -3px;
}

.dashboard-tab-inprogress {
    background-color: yellow !important;
    color: black;
}

.dashboard-tab-delivered {
    background-color: rgb(119, 162, 47) !important;
    color: #fff;
}

.dashboard-tab-cancel {
    background-color: rgb(244, 196, 196) !important;
    color: #000;
}

.AdminDashboard-tabs-right-side .css-19kzrtu {
    padding: 0px;
}

.AdminDashboard-tabs-rightside-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(21, 64, 129);
    color: white;
    padding: 23px 21px;
    border-radius: 10px 10px 0px 0px;
}

.rightsideheader-p {
    font-size: 13px;
    padding: 0px 17px;
    border-radius: 4px;
    font-weight: 500;
}

h5.rightsideheader-h5 {
    font-weight: 800;
    font-size: 17px;
}

h5.rightsideheader-h5 span {
    font-weight: 600;
    font-size: 13px;
}

.EditIcon_image {
    width: 20px;
}

.AdminDashboard-tabs-rightside-content {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AdminDashboard-tabs-rightside-content-left-side {
    width: 70%;
}

.AdminDashboard-tabs-rightside-content-right-side {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-self: baseline;
}

.AdminDashboard-tabs-rightside-content-left-side-upper-div {
    border: 3px solid rgb(210, 213, 205);
    ;
    border-radius: 10px;
    padding: 20px;
    margin: 24px;
    position: relative;

}

.AdminDashboard-tabs-rightside-content-left-side-lower-div {}

.AdminDashboard-tabs-rightside-content-inner-divs {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    margin-left: 0px;
}

.AdminDashboard-tabs-rightside-content-inner-divs:before {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 3px;
    content: ' ';
    margin: auto;
    border-bottom: 3px dashed #e3e3e3;
}

.AdminDashboard-tabs-rightside-content-inner-divs span {
    float: left;
    background: #FFF;
    position: relative;
    z-index: 1200;
    font-weight: 700;
    font-size: 15px;
}

.AdminDashboard-tabs-rightside-content-inner-divs strong {
    float: right;
    background: #FFF;
    position: relative;
    z-index: 1200;
    font-weight: 700;
    font-size: 15px;
}

.AdminDashboard-leftside-upper-div-p, .AdminDashboard-leftside-upper-div-h3 {
    text-align: left;
}

p.AdminDashboard-leftside-upper-div-p {
    font-size: 13px;
    font-weight: 500;
    margin: 0px !important;
    /* padding-left: 10px; */
}

h3.AdminDashboard-leftside-upper-div-h3 {
    color: rgb(35, 78, 147);
    font-weight: 700;
    font-size: 17px;
    /* padding-left: 10px; */
}

.AdminDashboard-tabs-rightside-content-dashline {
    border-bottom: 2px solid var(--theme-primary) dashed lightgrey;
    width: 75%;
}

.AdminDashboard-tabs-rightside-content-inner-divs-last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 8px;
    margin-top: 12px;
    margin-left: 0px;
}

.AdminDashboard-tabs-rightside-content-inner-divs-last:before {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 3px;
    content: ' ';
    margin: auto;
    border-bottom: 3px dashed #e3e3e3;
}

.AdminDashboard-tabs-rightside-content-inner-divs-last span {
    float: left;
    background: #FFF;
    position: relative;
    z-index: 1200;
    font-weight: 700;
    color: rgb(35, 78, 147);
    font-size: 15px;
}

.AdminDashboard-tabs-rightside-content-inner-divs-last strong {
    float: right;
    background: #FFF;
    position: relative;
    z-index: 1200;
    font-weight: 700;
    color: rgb(35, 78, 147);
}

.AdminDashboard-tabs-rightside-content-inner-divs-last h6 {
    color: rgb(35, 78, 147);
    font-weight: 600;
    font-size: 14px;
}

.AdminDashboard-tabs-rightside-content-left-side-lower-div .AdminDashboard-leftside-upper-div-h3 {
    font-size: 15px;
    font-weight: 700;
}

.AdminDashboard-tabs-rightside-content-left-side-lower-div .AdminDashboard-tabs-rightside-content-inner-divs h6 {
    width: 44%;
}

.AdminDashboard-tabs-rightside-content-right-side-btn {
    background: linear-gradient(to right, rgb(150, 150, 150), rgb(109, 109, 109));
    color: white !important;
    font-weight: 600;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 77%;
    margin: auto !important;
    margin-top: 22px !important;
}

.active-btndashboard {
    background: linear-gradient(to right, rgb(243, 121, 43), rgb(238, 45, 108)) !important;
}

.Dashboard-accept-order-button-wrapper {
    margin: 28px;
    margin-top: 0px;

}

.Dashboard-accept-order-button {
    background-color: rgb(119, 162, 47) !important;
    color: #fff !important;
    width: 80%;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
}

.DesktopMain-wrapper .MainHeader-logo-div img {
    width: 24%;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.Inner-tab.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    position: relative;
}

/* .Mui-selected::after {
    content: '';
    width: 0;
    height: 0;
    right: -19px;
    top: 22px;
    border-top: 12px solid transparent;
    border-left: 20px solid rgb(215 220 205);
    border-bottom: 13px solid transparent;
    position: absolute;
} */
.Details_page_agree_checkBox {
    text-align: start;
    display: flex;
    justify-content: start;
    align-items: center;
}

.Details_page_agree_checkBox p {
    font-size: 12px;
}

.Single-tab-wrapper-main .css-1cgx7kx-MuiTabs-scroller {
    overflow-y: unset !important;
    overflow-x: unset !important;
}

.Single-tab-wrapper-main .css-1numsu8-MuiTabs-root {
    overflow: unset !important;
}

.Dashboard-accept-order-button-wrapper-cancel {
    margin: 28px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Dashboard-accept-order-button-cancelrefund {
    background-color: rgb(227, 23, 76) !important;
    color: #fff !important;
    width: 60% !important;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    margin: 10px !important;
    margin-top: 0px !important;
}

.Dashboard-accept-order-button-cancel {
    background-color: rgb(204, 0, 1) !important;
    color: #fff !important;
    width: 60% !important;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    margin: 10px !important;
    margin-top: 0px !important;
}

.Dashboard-accept-order-button-none {
    background: linear-gradient(to right, rgb(150, 150, 150), rgb(109, 109, 109));
    color: white !important;
    font-weight: 600;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 77%;
    margin-top: 22px !important;
}

.dashboard-tab-cancelled {
    background-color: rgb(244, 196, 196) !important;
}

.Header-login-logout-btn {
    cursor: pointer;
}

.right-icon-class-wrapper {
    position: absolute;
    right: -12px;
    top: -13px;
    background-color: #77b43f;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid var(--theme-primary) solid white;
}

.right-icon-class-wrapper svg {
    color: #fff;
    padding: 3px;
}

.wrong-icon-class-wrapper {
    position: absolute;
    right: -12px;
    top: -13px;
    background-color: rgb(204, 0, 1);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid var(--theme-primary) solid white;
}

.wrong-icon-class-wrapper svg {
    color: #fff;
    padding: 3px;
}


/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================AdminStores===================================================================================================================================================================================================================================== */
/* .AdminStore-page-table-wrapper table {
    width: 100%;
    margin: auto;
    border-collapse: collapse;
}
.AdminStore-page-table-wrapper table thead{
background-color:rgb(233,238,244);
color:rgb(35,78,147);
}
.Stores-page-content-whole-wrapper {
    background: White !important;
    width: 92%;
    margin: auto !important;
    margin-top:5rem !important;
    margin-bottom:5rem !important;
}


.AdminStore-page-table-wrapper table thead th {
    padding: 11px 11px;
    font-size: 15px;
    text-align: left;
  
}
.AdminStore-page-table-wrapper table tbody tr{
    border-bottom:1px solid rgb(233,238,244);
    padding: 8px 18px !important;
}
.AdminStore-page-table-wrapper table tbody td{
    text-align: left ;
}
.Dashboard-page-tab-header.Stores-page-header{
    padding: 23px;
}
.Dashboard-page-tab-header.Stores-page-header h3 {
    font-size: 25px;
}
.Store-page-table-image-class {
    width: 50%;
    padding: 10px;
    border: 2px solid var(--theme-primary) solid rgb(233,238,244);
}
.StorePage-storeName-td{
    padding-left:0px;
    width: 40%;
}
.StorePage-storeName-td div h4 {
    font-weight: 900;
    font-size: 15px;
}
.StorePage-storeName-td div p {
    font-size: 13px;
}
.AdminStore-page-table-wrapper .css-qgjprd-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 40px;
    margin-top: 6px;
}  
.AdminStore-page-table-wrapper span.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
    right:0px !important;
    bottom:0px !important; 
}
.AdminStore-page-table-wrapper .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
 left:0px !important;
}
.AdminStore-page-table-wrapper .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-family: 'Poppins', sans-serif !important;
} */
/* ==============================End===================================================================================================================================================================================================================================== */

/* ===================HelpPage=============================================================================================================================================================================================================================================================== */
.Help-icon-wrapper {
    position: fixed;
    bottom: 0rem;
    right: 31rem;
    cursor: pointer;
}

.No_image_div {
    background: #f0f0f0;
    height: 114px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.Help-icon-wrapper img {
    object-fit: cover;
    width: 70px;
    height: 70px;
}

.Help-page-Header-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 24px;
}

.Help-page-Header-inner-div-one {
    display: flex;
}

.Help-page-Header-div-h4 {
    font-weight: bold;
    font-size: 20px;
    font-style: normal;
}

.Help-Page-Main-content-wrapper {
    min-height: 80vh;
    background-color: #F2F2F2;
    padding-top: 100px;
    padding-bottom: 50px;
}

.Help_page-content-p {
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    text-align: start;
    margin: 20px !important;
    font-weight: 500;
}

.HelpUs_page_accordian_content_wrapper {
    margin: 24px;
    box-shadow: 0px 0px 17px #00000029;
}

h3.Help_page-content-h3 {
    text-align: start;
    margin: 20px !important;
    font-weight: bold;
    font-size: 14px;
}

.Help_page-content-h3 span {
    color: var(--theme-primary);
}

.Help_page_content_button {
    text-align: left;
    background: transparent;
    border: 1px solid var(--theme-primary);
    color: var(--theme-primary);
    font-weight: 700;
    border-radius: 7px;
    padding: 7px 16px;
}

.HelpPage_Accordian_Details_wrapper {
    /* border-top:2px solid var(--theme-primary); */
}

.Help_page_content_button_div_wrapper {
    text-align: left;
    margin: 20px !important;
}

.Help_page_content_single_content_wrapper {
    border-bottom: 1px solid lightgrey;
}

.Help_page-content-p_two {
    font-weight: 400;
    color: var(--theme-primary);
    margin-top: 7px !important;
    text-decoration: underline;
}

h6.Help_page-content-h6 {
    text-align: start;
    margin: 20px !important;
    font-size: 12px;
    font-weight: 500;
}

.Help_page_content_heading {
    font-size: 16px;
    margin-left: 20px !important;
    font-weight: 500;

}

.HelpUs_page_accordian_content_wrapper .Mui-expanded .Help_page_content_heading, .HelpUs_page_accordian_content_wrapper .Mui-expanded svg {
    color: var(--theme-primary);
}

/* ===================End=============================================================================================================================================================================================================================================================== */
/* ===================MoreDetails=============================================================================================================================================================================================================================================================== */
.MoreDetials_page_Banner_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    background-color: #F0F0F0;
    border-radius: 7px;
}

.MoreDetials_page_Banner_wrapper img {
    width: 35%;
}

.MoreDetails_page_wrapper {
    padding-top: 80px;
}

.More_Details_Page_h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0px !important;
    text-align: left;
    padding: 20px;
    padding-bottom: 0px !important;
}

.More_Details_Page_h5 {
    font-weight: 700;
    color: var(--theme-primary);
    line-height: 19px;
    margin-bottom: 0px;
    font-size: 13.9px;
    text-align: start;
}

.More-details-page-content-p {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    text-align: start;
}

.ItemListMain-Wrapper h2 {
    font-size: 20px;
    text-align: left;
}

/* ===================End=============================================================================================================================================================================================================================================================== */

/* ==============================Responsive===================================================================================================================================================================================================================================== */
@media only screen and (max-width: 1024px) {
    .MainHeader-wrapper {
        /* padding: 15px 10px 5px; */
    }

    .AdminDashboard-tabs-rightside-content {
        display: block !important;
    }

    .AdminDashboard-tabs-rightside-content-left-side {
        width: 100%;
    }

    .AdminDashboard-tabs-rightside-content-right-side {
        width: 100%;
    }

    .AdminDashboard-whole-Main-wrapper {
        padding: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .AdminDashboard-tabs-left-side {
        width: 50%;
        background: #fff;
        margin: 10px;
    }

    .AdminDashboard-tabs-right-side {
        width: 50%;
        background: #fff;
        margin: 10px;
    }

    .AdminDashboard-tabs-rightside-content {
        padding: 0px;
    }

    .Dashboard-accept-order-button {
        width: 100%;
    }

    .Dashboard-accept-order-button-wrapper {
        margin: 28px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 560px) {
    .StorePage-storeName-td div p {
        font-size: 11px;
    }

    .Product_page_header_upper img {
        width: 140px;
    }

    .Product_page_outer_div {
        width: 100%;
        padding-top: 130px;
    }

    .ItemListMain-Wrapper {
        padding-top: 55px;
    }

    .StorePage-storeName-td div h4 {
        font-size: 13px;
    }

    .Store-page-table-image-class {
        width: 75%;
    }

    .AdminStore-page-table-wrapper .css-qgjprd-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 36px;
        width: 160px !important;
    }

    .AdminStore-page-table-wrapper .css-j204z7-MuiFormControlLabel-root {
        margin-left: -2px solid var(--theme-primary);
    }

    .MainHeader-logo-div p {
        top: 23px;
    }

    .MainHeader-logo-div img {
        width: 85%;
    }

    .Storelist-container-wrapper h3 {
        text-align: left;
        font-size: 20px;
    }

    #Store_dropdown_select button {
        font-size: 10px;
    }

    .thumbnail-image {
        width: auto;
        height: 10px;
        margin-right: 5px;
    }

    .Storelist-container-wrapper {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 560px) {
    .banner__text {
        width: 100%;
    }

    .MainHeader-logo-div-wrapper {
        width: 100%;
    }

    .Footer_Content_wrapper {
        width: 100%;
    }

    .discount__container {
        width: 100% !important;
    }

    .Congratulations_page_estimated_time_div {
        width: 100%;
    }

    .Select_delivery_page_next_btn_wrapper, .Details-page-Nextbutton-wrapper, .Details_page_selected_deivery_location_wrapper, .Congratulations_page_estimated_time_div_grey {
        width: 100%;
        padding: 12px 5%;
        left: 0;
        transform: translate(0);
    }

    .Details_page_selected_deivery_location_wrapper {
        margin: 0 5%;
        width: 80%;
    }

    .Details_page_disabled_button, .Details-page-Nextbutton-wrapper button {
        font-size: 16px;
        padding: 15px 20px;
    }

    .AdminDashboard-whole-Main-wrapper-div-one .MuiBox-root.css-orkwbp {
        display: block;
    }

    .AdminDashboard-tabs-left-side {
        width: 100%;
        margin: -6px;
    }

    .AdminDashboard-tabs-right-side {
        width: 100%;
        margin: -6px;
    }

    .Billpage_proceed_tonext_btn_wrapper {
        width: 100%;
    }

    .ProductList_page_next_button_wrapper {
        width: 90%;
    }

    .Productlist_page_content_wrapper {
        /* padding-top: 300px !important; */
    }
}

@media screen and (max-width:600px) {
    .MainWhole-wrapper-container-2 {
        width: 100% !important;
    }

    body {
        overflow-x: hidden !important;
    }

    .MainWhole-wrapper-container {
        width: 100% !important;
        /* height: 100vh; */
    }

    /* .ItemList_Page_Next_button{
        width: 85% !important;
        left:0 !important;
        right:unset !important;
    } */



    .CartPage-Main-wrapper .DetailsPage-content-wrapper-inner:nth-child(1) {
        display: block !important;
    }

    .CartPage-Main-wrapper .ItemsListPage-ItemsList-Counter-wrapper {
        margin-left: 0rem;
    }

    .Congratulation-content-div-one-wrapper-inner-two h1 {
        font-size: 26px;
    }

    .Congratulation-content-div-one-wrapper-inner-two p {
        font-size: 17px;
    }

    .Congratulation-content-div-one-wrapper-inner-one img {
        width: 74%;
    }

    .HomePage_slider_div-class {
        background-size: 182px !important;
        margin-top: 15px;
    }

    h1.HomePage-Main-Heading-text {
        width: 76%;
        margin: auto !important;
        font-size: 24px;
        line-height: 31px;
        font-weight: 800;
    }

    .HomePage_slider_content_div_content h4 {
        margin-top: 20px !important;
        font-size: 20px;
        width: 100%;
        line-height: 24px;
    }

    .HomePage_slider_content_div_content {
        margin-bottom: 0rem;
        margin-left: 0px;
        width: 100%;
        text-align: center;
    }

    .owl-carousel .owl-stage-outer {
        margin-bottom: 0px;
    }

    .owl-theme .owl-nav {
        top: 20px;
    }

    .HomePage-Main-Wrapper-Container .owl-dots {
        margin-top: 15px;
    }

    .HomePage_order_now_button {

        padding: 8px 45px;
        color: white;
        border: none !important;
        border-radius: 10px;
        font-size: 28px;
        font-weight: 600;
        margin: 20px 10px !important;
        cursor: pointer;
        position: relative;
        z-index: 1200 !important;
        margin-top: 25px !important;
    }
}

/* ==============================End===================================================================================================================================================================================================================================== */
.react-tel-input .country-list .country {
    position: relative;
    padding: 12px 9px 13px 46px;
    text-align: justify;
}

@media screen and (max-width:420px) {
    .HomePage_slider_content_div_content h4 {
        width: 60%;
        margin: auto !important;
        margin-top: 22px !important;
    }

    .Cart_page_otp_input input {
        border: 2px solid #ddd;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 700;
        height: 30px !important;
        margin: -6px;
        width: 30px !important;
    }
}

@media screen and (max-width: 375px) {
    /* .ItemsListPage-ItemsList-containers {
        grid-template-columns: 50% 50%;
    } */

    .cart__product__list__wrapper {
        /* width: 60%; */
    }

    .cart__order__price {
        width: 40%;
    }

    .SelectDelivery_page_error_p, .BillPage_proceed_to_btn {
        font-size: 0.90rem;
    }

}


@media screen and (min-width: 326px) and (max-width: 425px) {
    .SelectDelivery_page_error_p, .BillPage_proceed_to_btn {
        font-size: 1rem;
    }
}


@media screen and (max-width: 375px) {

    .ItemsListPage-ItemsList-containers,
    .cart__item__list__container {
        grid-template-columns: 45% 55%;
        grid-template-columns: none;
        row-gap: 10px;
        /* padding-bottom: 0px; */
    }

    .product-content-wrapper-inner-div-one {
        width: 50%;
    }

    .cart__product__list__container {
        /* justify-content: start; */
    }

    .cart__product__list__wrapper {
        /* width: 35%; */
    }

    .cart__order__price {
        width: 30%;
    }
}

.readonly-input {
    pointer-events: none;
    /* Disable mouse and keyboard interactions */
    background-color: #f7f7f7;
    /* Optional: Set a background color for visual indication */
}

.store_location {
    padding-top: 5px;
    padding-bottom: 0;
    font-size: 14px;
    text-align: left;
    color: var(--theme-primary);
    font-family: 'Poppins';
    font-weight: bold;
}

.shop__now__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 5px 20px;
    border-radius: 7px;
    background-color: var(--theme-primary);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    font-size: .80rem;
}

.variant__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.store__name {
    background: white;
    color: var(--theme-primary);
    width: max-content;
    position: absolute;
    bottom: -50px;
    padding: 10px 30px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 17px #00000029;
}

.label__product {
    font-style: italic;
    font-size: 0.75rem;
    color: var(--theme-primary) !important;
    margin-top: -2px !important;
}

.product__ingredients {
    display: flex;
    gap: 3px;
    align-items: center;
}

.product__view__cart {
    background-color: white;
    border: none;
    color: var(--theme-primary);
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.variants__modal__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.variant__modal__close__btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid red;
    border-radius: 20px;
}

.variant__modal__close__btn__icon {
    color: red;
    cursor: pointer;
}

.variants__modal__product {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* padding: 30px; */
    /* justify-content: end; */
    height: 100%;
    flex-grow: 1;
}

.variants__increment__modal__product {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* padding: 30px; */
    height: 100%;
}

@media only screen and (max-width: 375px) {
    .variants__modal__container {
        height: calc(100% - 50px) !important;
    }
}

@media only screen and (max-height: 820px) {
    .variants__modal__container {
        height: calc(100% - 50px) !important;
    }

    .variants__modal__wrapper {
        height: 100%;
    }
}

.variant__modal__product__div {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    /* height: 20%; */
    padding: 30px 30px 0px 30px;
}

@media only screen and (max-width: 575px) {
    .variant__modal__product__div {
        flex-direction: column;
        align-items: baseline;
        justify-content: start;
    }
}

.variant__modal__product__add__div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    /* height: 20%; */
    /* padding: 30px 30px 0px 30px; */
}

.variants__increment__modal__product__image {
    box-shadow: 0px 0px 6px #0000001a;
    border-radius: 3px;
    width: 100px;
    height: 100px;
    border-radius: 7px;
    /* width: 100%;
    height: 100%; */
    position: relative;
}

.variants__modal__product__image {
    /* box-shadow: 0px 0px 6px #0000001a; */
    border-radius: 18px;
    width: 100%;
    height: 100%;
    position: relative;
}

.variants__modal__product___details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}

.variants__modal__product___name {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
}

.variants__increment__modal__variant___name {
    font-weight: 400;
    font-size: 14px;
}

.variants__modal__product___price {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: var(--theme-primary);
}

.variants__modal__product___ingredients {
    display: flex;
    gap: 3px;
    align-items: center;
    flex-wrap: wrap;
}

.variants__modal__product__ingredients__image {
    width: 20px;
    height: 20px;
}

.variant__name__modal__divider {
    margin: 0;
    border: none;
    border-bottom: 0.2rem solid rgba(0, 0, 0, 1);
    opacity: 0.3;
    /* margin-inline: 10px; */
}

.recommended__product__divider {
    margin: 0;
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.20);
    opacity: 0.3;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-inline: 10px;
}

.variant__modal__divider {
    margin: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    opacity: 0.3;
    margin-inline: 10px;
}

.variant__increment__modal__divider {
    margin: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    opacity: 0.3;
    /* margin-inline: 10px; */
}

.variant__modal__variants__div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    /* height: 80%; */
}

.variant__modal__variants__details {
    display: flex;
    flex-direction: column;
    /* height: 10%; */
    padding-inline: 30px;
}

.variant__modal__variant__name {
    font-weight: bold;
    font-size: 16px;
    color: var(--theme-primary);
}

.variant__increment__modal__variant__name {
    font-weight: bold;
    font-size: 20px;
    color: black;
}

.variant__modal__variant__select__label {
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    color: black;
    width: max-content;
    border-bottom: 5px solid rgba(var(--theme-primary-hex), 0.3);
}

.variant__modal__variants__options {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 15px;
}

.variant__modal__variant__option {
    width: 50%;
    font-size: 12px;
    margin-bottom: 10px;
}

.variant__modal__variant__select__optional {
    font-weight: 200;
}

.variant__modal__recommended__product__details {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    /* height: 10%; */
    margin-inline: 30px;
}

.variant__increment__modal__variant__details {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    /* height: 10%; */
}

.variant__modal__recommended__product__div {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding-inline: 20px;
    margin-inline: 5px;
}

.variant__increment__modal__variant__div {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}

.variant__increment__modal__variant__details__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 55px;
}

.variants__modal__recommended__product__image {
    /* box-shadow: 0px 0px 6px #0000001a; */
    /* border-radius: 3px; */
    width: 100px;
    height: auto;
    position: relative;
}

.variants__modal__recommended__product___details {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.variants__modal__recommended__product__add__btn__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    /* margin-bottom: -10px !important; */
    background: white;
    /* position: absolute; */
    bottom: -20px;
    right: 20%;
    color: var(--theme-primary);
    width: 100px
}

.variant__increment__modal__variant__add__btn__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    box-shadow: 0px 0px 6px #0000001A;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    /* margin-bottom: -10px !important; */
    background: white;
    /* position: absolute; */
    bottom: -20px;
    right: 20%;
}

.variant__increment__modal__variant__add__btn__wrapper button {
    width: 100%;
    background: transparent;
    border: none;
    color: var(--theme-primary);
    font-weight: bold;
    cursor: pointer;
    padding: 8px 10px;
    font-size: 14px;
    font-family: "Poppins";
}

.variants__modal__recommended__product__add__btn {
    width: 100%;
    background: transparent;
    border: none;
    color: var(--theme-primary);
    font-weight: bold;
    cursor: pointer;
    /* padding: 8px 10px; */
    font-size: 14px;
    font-family: "Poppins";
    padding-top: 3px;
}

.variants__modal__recommended__product__checked__btn {
    width: 100%;
    background: transparent;
    border: none;
    color: green;
    font-weight: bold;
    cursor: pointer;
    padding: 8px 10px;
    font-size: 14px;
    font-family: "Poppins";
}

.variants__modal__recommended__product__checked__btn__icon {
    width: 20px;
    height: auto;
}

.variant__modal__add__to__cart__wrapper {
    position: sticky;
    bottom: 0;
    /* background-color: var(--theme-primary); */
    background-color: white;
    width: 100%;
    left: 0;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.variant__modal__cart__items__change {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    width: 100%;
}

.variant__modal__variant__plus__btn,
.variant__modal__variant__minus__btn,
.variant__modal__variant__items__count {
    color: var(--theme-primary);
    font-weight: bold;
    font-size: 24px;
}

.variant__modal__variant__plus__btn,
.variant__modal__variant__minus__btn,
.variant__modal__add__to__cart__text {
    cursor: pointer;
}

.variant__modal__add__to__cart__btn {
    background-color: white;
    border: none;
    color: var(--theme-primary);
    font-size: 19px;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.variant__modal__add__to__cart__btn h3 {
    color: var(--theme-primary);
    font-weight: bold;
    text-align: start;
    padding-left: 14px;
}

.variant__modal__add__to__cart {
    background-color: var(--theme-primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 20px; */
    width: 100%;
    border-radius: 7px;
}

.variant__modal__cart__items__price {
    color: white;
    font-size: 22px;
    font-weight: bold;
    padding: 10px;
}

.variant__modal__add__to__cart__text {
    color: var(--theme-primary);
    background-color: white;
    font-weight: bold;
    text-align: start;
    font-size: 16px;
    padding: 20px 30px;
    border-radius: 7px;
}

.variant__modal__recommended__product__wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.variant__increment__variant__wrapper {
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    gap: 20px;
}

.variant__increment__modal__variant__wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 20px;
    /* height: calc(45% - 130px); */
}

.variant__modal__variants__div .MuiFormControlLabel-label {
    margin: 0;
    font-family: "Poppins", "Arial", sans-serif;
    font-size: 12px;
    /* line-height: 0; */
    letter-spacing: 0;
}

.variant__modal__variants__div .Mui-checked {
    color: var(--theme-primary) !important;
}

.variant__modal__variants__div .MuiRadio-root {
    padding: 5px;
}

.variant__modal__recommended__product__div__wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
}

/* @media only screen and (max-height: 770px) {
    .variant__modal__recommended__product__wrapper {
        height: calc(45% - 130px);
    }
} */

.dropdown-menu {
    padding: 0px !important;
    border-radius: 7px !important;
}

.dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 4px !important;
    font-size: 14px !important;
    padding: 7px 7px !important;
}

.dropdown-item:nth-child(1) {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
}

.dropdown-item:nth-last-child(1) {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
}

.variant__increment__modal__add__new__variant__wrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    height: 55px;
    background-color: var(--theme-primary);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.variant__increment__modal__add__new__variant__or__text {
    position: absolute;
    top: -15px;
    left: 50%;
    border-radius: 20px;
    border: 1px solid var(--theme-primary);
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: white;
    color: var(--theme-primary);
    padding: 5px;
}

.variant__increment__modal__add__new__variant__label {
    color: white;
    font-size: 17px;
    font-weight: bold;
    margin-top: 10px;
}

.discount__container {
    position: fixed;
    bottom: 110px;
    background-color: white;
    display: grid;
    grid-template-columns: 65% 35%;
    width: 560px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 3px 6px #00000029;
    align-items: center;
}

.no__scroll {
    overflow: hidden !important;
}

.delivery__location__wrapper .MuiBottomNavigationAction-label {
    font-style: normal !important;
    padding: 0.5rem 0.75rem !important;
    height: 100% !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-family: "Poppins", "Arial", sans-serif;
    /* text-transform: lowercase !important; */
}

.delivery__location__wrapper .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.75rem !important;
    font-style: normal !important;
    background-color: var(--theme-primary) !important;
    color: white !important;
    border-radius: 7px !important;
    font-style: normal !important;
    font-family: "Poppins", "Arial", sans-serif;

}

.delivery__location__wrapper .MuiBottomNavigationAction-root {
    padding: 0 !important;
    border-radius: 7px !important;
    font-style: normal !important;
    max-width: max-content !important;
    min-width: max-content !important;
}

.delivery__location__wrapper .MuiBottomNavigationAction-root.Mui-selected {
    color: white !important;
    border-color: 2px solid rgba(var(--theme-primary-hex), 1);
}

/* .delivery__location__wrapper .MuiBottomNavigation-root {
    justify-content: start !important;
} */

.customer__phone__number .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet-active {
    background-color: var(--theme-primary) !important;
}